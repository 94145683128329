// #form-document-container > div > div > div:last-of-type {
//   box-shadow: none !important;
//   border-radius: none !important;
//   padding: var(--spacing-4) !important;
//   background-color: transparent !important;
// }

#form-document-container {
  background-color: transparent !important;
  div {
    .ff-wrapper {
      font-family: var(--font-family-body) !important;
      background-color: white !important;
      box-shadow: 0px 2px 12px 0px #b2b2b280 !important;
      border-radius: var(--border-radius-xl) !important;

      div:has(> .ff-form-wrapper) {
        background-color: white !important;
        box-shadow: 0px 2px 12px 0px #b2b2b280 !important;
        border-radius: var(--border-radius-xl) !important;
      }

      .f1-px-3xl {
        padding-left: var(--spacing-4) !important;
        padding-right: var(--spacing-4) !important;
      }

      .f1-text-sm.f1-text-tertiary-grey-500.ff-subtitle {
        font-family: var(--font-family-heading) !important;
        font-size: var(--font-size-sm) !important;
        color: var(--colour-grey-4) !important;
      }

      .ff-document-item {
        font-family: var(--font-family-body) !important;
        justify-content: start !important;
        align-items: center !important;
        div {
          div {
            h5 {
              font-family: var(--font-family-body) !important;
              font-weight: var(--font-weight-medium) !important;
            }
          }
        }
        .my-auto {
          margin-left: var(--spacing-4) !important;
        }
      }
    }

    .ff-wrapper:has(> div > .ff-form-wrapper) {
      box-shadow: none !important;
      border-radius: none !important;
      background-color: transparent !important;

      :last-child {
        background-color: transparent;
        .ff-button,
        .ff-cancel-button {
          font-family: var(--font-family-mono) !important;
          margin-top: var(--spacing-4) !important;
        }
      }
    }

    .f1-text-sm {
      font-family: var(--font-family-body) !important;
      color: var(--colour-grey-4) !important;
    }

    .f1-font-semibold {
      font-family: var(--font-family-body) !important;
      font-weight: var(--font-weight-medium) !important;
      color: var(--colour-grey-5) !important;
      font-size: var(--font-size-base) !important;
    }

    .ff-wrapper:has(> div > .ff-title) {
      box-shadow: none !important;
      border-radius: none !important;
      background-color: transparent !important;

      div:has(> .ff-title) {
        background-color: white !important;
        box-shadow: 0px 2px 12px 0px #b2b2b280 !important;
        border-radius: var(--border-radius-xl) !important;
        padding-bottom: var(--spacing-2) !important;
      }

      :last-child {
        background-color: transparent;
        box-shadow: none !important;
        .ff-button,
        .ff-cancel-button {
          margin-top: var(--spacing-2) !important;
        }
      }
    }

    .ff-wrapper:has(> div > .ff-progress-wrapper) {
      box-shadow: none !important;
      border-radius: none !important;
      background-color: transparent !important;

      div {
        overflow: hidden;
      }

      :last-child {
        background-color: transparent;
        box-shadow: none !important;
        .ff-button,
        .ff-cancel-button {
          margin-top: var(--spacing-2) !important;
        }
      }
    }

    .ff-wrapper:has(> .ff-progress-wrapper) {
      box-shadow: none !important;
      border-radius: none !important;
      background-color: transparent !important;
      .ff-progress-wrapper {
        background-color: transparent !important;
      }
    }

    .ff-button {
      font-family: var(--font-family-mono) !important;
      color: white !important;
      font-weight: var(--font-weight-medium) !important;
    }

    .ff-wrapper:has(> div > .ff-progress-wrapper > div > img[alt='state icon']) {
      background-color: white !important;
      div {
        .ff-progress-wrapper {
          div {
            div {
              font-family: var(--font-family-body) !important;
              font-weight: var(--font-weight-medium) !important;
            }
            .f1-text-ter {
              font-family: var(--font-family-body) !important;
              .ff-progress-item {
                font-size: var(--font-size-sm) !important;
                font-weight: var(--font-weight-normal) !important;
                margin-top: var(--spacing-2) !important;
                display: list-item;
                list-style-type: square;
                list-style-position: inside;
                text-align: left;
                margin-left: var(--spacing-4) !important;
              }
            }
          }
        }
      }
    }
  }

  div:has(> .ff-wrapper > div > .ff-form-wrapper) {
    background-color: transparent !important;
  }

  div:has(> .ff-wrapper > div > .ff-title) {
    background-color: transparent !important;
  }

  div:has(> .ff-wrapper > .ff-progress-wrapper) {
    background-color: transparent !important;
  }

  .ff-form-wrapper {
    label[for='givenName'],
    label.f1-text-sm,
    label[for='middleName'],
    label[for='familyName'],
    label[for='dateOfBirth'],
    label[for='address.fullAddress'] {
      font-weight: var(--font-weight-normal) !important;
      font-size: var(--font-size-base) !important;
      color: var(--colour-grey-5) !important;
    }
  }
}

#form-document-container .ff-document-form-description {
  padding: 0 !important;
  margin-bottom: var(--spacing-4) !important;
}

#form-document-container h1 > div > span.f1-font-normal {
  display: none !important;
}

#form-document-container > div > div > div > div[data-testid] {
  border-radius: var(--border-radius-xl) !important;
  padding: var(--spacing-2) !important;
}

#form-document-container > div > div > div > div[data-testid] > div > :last-child > img {
  width: var(--spacing-12) !important;
}

#form-document-container .ff-title > div {
  font-family: var(--font-family-heading) !important;
  font-size: var(--font-size-h1) !important;
  font-weight: 700 !important;
  color: var(--colour-grey-5) !important;
  min-height: 0 !important;
  margin: 0 !important;
}

#form-document-container input,
textarea {
  border-radius: var(--border-radius-lg) !important;
}

#form-document-container .ff-outcome-message .ff-title {
  font-family: var(--font-family-body) !important;
  font-size: var(--font-size-base) !important;
  font-weight: 500 !important;
  color: var(--colour-grey-5) !important;
}

#form-document-container,
#form-document-container p,
#form-document-container div,
#form-document-container input,
#form-document-container textarea,
#form-document-container select,
#form-document-container .ff-text-link {
  font-family: var(--font-family-body) !important;
  font-size: var(--font-size-base) !important;
  font-weight: 400 !important;
  color: var(--colour-grey-5) !important;
}

#form-document-container .f1-font-medium {
  font-weight: 500 !important;
  color: var(--colour-grey-5) !important;
}

#form-document-container .ff-text-link {
  color: var(--colour-brand-secondary) !important;
  margin-top: var(--spacing-2) !important;
}
#form-document-container .ff-text-link:hover {
  color: var(--colour-brand-secondary-darker-1) !important;
}

#form-document-container .ff-feedback.ff-display-error.ff-errors {
  color: var(--colour-utility-error) !important;
}

#form-document-container .ff-main-label {
  font-weight: 500 !important;
}

#form-document-container .ff-hint-label,
#form-document-container .helper-text .icon-outline {
  display: none !important;
}

#form-document-container .helper-text .document-number-info-text {
  display: block !important;
}

#form-document-container .ff-form,
#form-document-container .ff-form .vs__selected-options,
#form-document-container .ff-form .vs__selected,
#form-document-container .ff-form .vs__search,
#form-document-container .ff-form .ff-stack,
#form-document-container .ff-form .ff-scrollable,
#form-document-container .ff-form .ff-stack .ff-document-type {
  padding: 0 !important;
  margin: 0 !important;
}

#form-document-container .ff-native-select-icon {
  top: 46px !important;
}

#form-document-container .ff-review-input .ff-document-review-input:last-child {
  margin-bottom: 0 !important;
}

#form-document-container .ff-form .ff-feedback,
#form-document-container .ff-form .ff-text-link {
  margin-top: var(--spacing-2) !important;
}

#form-document-container .ff-form .ff-review-summary {
  background-color: var(--colour-white) !important;
}
#form-document-container .ff-form .ff-summary-card .ff-review-title {
  font-weight: 500 !important;
}
#form-document-container .ff-form .ff-summary-card .ff-review-label {
  color: var(--colour-grey-3) !important;
}

#form-document-container .ff-details-review .bottom-drawer .ff-dialog-content {
  bottom: 80px !important;
}

#form-document-container > .ff-form-wrapper,
> .ff-wrapper {
  padding: var(--spacing-2) !important;
}

#form-document-container .ff-form-wrapper .ff-form-field > div > div.f1-border {
  border-width: 0 !important;
}

#form-document-container .ff-form-wrapper .ff-form-field > div > div > div.f1-border {
  border-width: 0 !important;
}

#form-document-container .ff-button-wrapper {
  margin: var(--spacing-6) 0 !important;
  padding: 0 !important;
  box-shadow: none !important;
  background: transparent !important;
  display: flex !important;
  gap: 8px !important;
  align-items: center !important;
  justify-content: center !important;
}

#form-document-container .ff-description:first-of-type {
  font-size: var(--font-size-base) !important;
  font-weight: 500 !important;
}

#form-document-container .ff-button {
  padding: 0.875rem var(--spacing-6) !important;
  border-radius: var(--border-radius-pill) !important;
  border: 2px solid transparent !important;
  background-color: var(--colour-brand-primary) !important;
  color: var(--colour-white) !important;
  font-family: var(--font-family-mono) !important;
  font-size: var(--font-size-base) !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.16em !important;
  cursor: pointer !important;
  text-decoration: none !important;
  box-shadow: none !important;
  transition: all 0.2s ease-in-out !important;
  --tw-shadow: var(--box-shadow-drop);
  --tw-shadow-colored: var(--box-shadow-drop);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
#form-document-container .ff-button[disabled],
#form-document-container .ff-button[disabled]:hover {
  background-color: var(--colour-grey-2) !important;
  border-color: var(--colour-grey-2) !important;
  color: var(--colour-grey-5) !important;
  cursor: not-allowed !important;
  box-shadow: none !important;
}
#form-document-container .ff-basic-button:focus-visible {
  background-color: var(--colour-brand-primary-darker-2) !important;
}
#form-document-container .ff-basic-button:hover {
  background-color: var(--colour-brand-primary-darker-1) !important;
  box-shadow: 0px 4px 12px rgb(178 178 178 / 70%) !important;
}
#form-document-container .ff-basic-button:active,
#form-document-container .ff-basic-button:focus-visible {
  background-color: var(--colour-brand-primary-darker-2) !important;
}

#form-document-container .ff-review-buttons .ff-cancel {
  border: transparent !important;
  color: var(--colour-brand-secondary) !important;
  background: var(--colour-white) !important;
}
#form-document-container .ff-review-buttons .ff-cancel:hover {
  background: var(--colour-secondary-lighter-3) !important;
}
#form-document-container .ff-review-buttons .ff-cancel:active,
#form-document-container .ff-review-buttons .ff-cancel:focus-visible {
  background: var(--colour-secondary-lighter-2) !important;
}

#form-document-container .ff-basic-button {
  width: 328px !important;
}
#form-document-container .ff-review-buttons .ff-cancel,
#form-document-container .ff-review-buttons .ff-save {
  width: 228px !important;
}

#form-document-container .ff-document-type-button {
  border: 1px solid var(--colour-grey-3) !important;
  background: var(--colour-white) !important;
  border-radius: var(--border-radius-lg) !important;
}
#form-document-container .ff-document-type-button:hover {
  border-color: var(--colour-brand-secondary) !important;
  background: var(--colour-brand-secondary-lighter-3) !important;
}
#form-document-container .ff-goahead-button.ff-document-type-button[disabled] {
  border-color: var(--colour-grey-2) !important;
  background: var(--colour-grey-2) !important;
}

#form-document-container .ff-review-input .ff-review-buttons {
  display: flex !important;
  flex-direction: column !important;
  column-gap: 8px !important;
  align-items: center !important;
}
#form-document-container .ff-review-input .ff-review-buttons button:first-child {
  margin-right: 0px !important;
}

#form-document-container a {
  color: var(--colour-brand-secondary) !important;
  text-decoration: none !important;
}
#form-document-container a:hover {
  color: var(--colour-brand-secondary-darker-1) !important;
}

#form-document-container .ff-passport-number {
  margin-bottom: var(--spacing-4) !important;
}

#form-document-container .ff-review-input .ff-applicant-review-input.ff-summary-card {
  margin-top: var(--spacing-4) !important;
}

@media (min-width: 480px) {
  #form-document-container .ff-basic-button {
    max-width: 328px !important;
    min-width: fit-content !important;
    margin: 0 auto !important;
    justify-content: center !important;
  }
  #form-document-container .ff-review-input .ff-review-buttons {
    display: flex !important;
    flex-direction: column !important;
    column-gap: 8px !important;
    align-items: center !important;
  }
  #form-document-container .ff-review-buttons .ff-cancel,
  #form-document-container .ff-review-buttons .ff-save {
    max-width: 228px !important;
  }
  #form-document-container .ff-review-input .ff-review-buttons button:first-child {
    margin: 0px !important;
  }
}
@media (min-width: 640px) {
  #form-document-container .ff-basic-button {
    min-width: 328px !important;
    margin: 0 auto !important;
    justify-content: center !important;
  }
  #form-document-container .ff-review-input .ff-review-buttons {
    display: flex !important;
    flex-direction: row !important;
    gap: 8px !important;
    align-items: center !important;
  }
  #form-document-container .ff-review-buttons .ff-cancel,
  #form-document-container .ff-review-buttons .ff-save {
    max-width: fit-content !important;
    min-width: 228px !important;
  }
  #form-document-container .ff-review-input .ff-review-buttons button:first-child {
    margin-right: 20px !important;
  }
  .tablet\:f1-max-w-\[442px\] {
    max-width: 544px !important;
  }
  #form-document-container {
    .ff-button,
    .ff-cancel-button {
      width: 292px !important;
    }
  }
}

#form-document-container .f1-text-tertiary-red-600 {
  color: var(--colour-utility-error) !important;
  font-size: var(--font-size-sm) !important;
}

#form-document-container {
  .f1-text-tertiary-grey-500 {
    color: var(--colour-grey-4) !important;
    font-size: var(--font-size-sm) !important;
  }
  .ff-loading {
    order: 3;
    width: var(--spacing-12) !important;
  }
  .ff-progress-wrapper {
    overflow: hidden !important;
  }
  .ff-progress-wrapper:has(> div > svg) {
    overflow: hidden !important;
    order: 2;
    .f1-text-ter {
      margin-top: var(--spacing-2) !important;
      .ff-progress-item {
        font-weight: var(--font-weight-medium) !important;
      }
    }
  }
  .frankie-select-options {
    background-color: white !important;
  }
}
