.badge {
  @apply flex items-start gap-6 w-fit rounded border px-2 py-1 text-grey-5 text-sm;

  &-success {
    @apply border-tertiary bg-tertiary-lighter-3;
  }

  &-secondary {
    @apply bg-secondary-lighter-3 border-secondary-lighter-1;
  }

  &-tertiary {
    @apply bg-grey-1 text-grey-4 border-grey-4;
  }

  &-action {
    @apply border-error bg-error-fill;
  }

  &-warning {
    @apply border-warning bg-warning-fill;
  }
}
