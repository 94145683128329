.root {
  @apply bg-grey-1 mx-auto flex w-full flex-row items-start justify-evenly gap-4 rounded-xl p-4;

  &-invalid {
    @apply border-error border;
  }
}

.item {
  @apply flex flex-col items-center justify-start gap-2 text-sm;

  button {
    @apply border-grey-3 inline-flex h-6 w-6 items-center rounded-full border bg-white;

    svg {
      @apply invisible;
    }

    &[data-state='checked'] {
      @apply border-0;
      svg {
        @apply visible;
      }
    }
  }
}
