// Custom Utilities

.bg-gradient-primary {
  background-image: $gradient-primary;
}

.bg-gradient-primary-dark {
  background-image: $gradient-primary-dark;
}

.bg-gradient-primary-light {
  background-image: $gradient-primary-light;
}

.bg-gradient-primary-lighter {
  background-image: $gradient-primary-lighter;
}

.bg-gradient-secondary {
  background-image: $gradient-secondary;
}

.bg-gradient-secondary-dark {
  background-image: $gradient-secondary-dark;
}

.bg-gradient-secondary-light {
  background-image: $gradient-secondary-light;
}

.bg-gradient-secondary-lighter {
  background-image: $gradient-secondary-lighter;
}

.bg-gradient-tertiary {
  background-image: $gradient-tertiary;
}

.bg-gradient-tertiary-dark {
  background-image: $gradient-tertiary-dark;
}

.bg-gradient-tertiary-light {
  background-image: $gradient-tertiary-light;
}

.bg-gradient-tertiary-lighter {
  background-image: $gradient-tertiary-lighter;
}

.bg-gradient-grey {
  background-image: $gradient-grey;
}

.bg-gradient-white {
  background-image: $gradient-white;
}
