.svg-container {
  @apply flex items-center pr-4;
}

.li-container:hover path {
  @apply fill-secondary;
}
.li-container {
  @apply hover:bg-secondary-lighter-3 text-grey-5 my-1 flex cursor-pointer p-4;
}
.selected {
  @apply bg-secondary-lighter-3 font-medium;
  path {
    @apply fill-secondary;
  }
}
