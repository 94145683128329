.select-trigger {
  @apply border-grey-2  w-full bg-transparent focus:outline-none text-left;

  div > span {
    @apply inline-flex w-full items-center justify-between;
  }
  .form-field {
    @apply flex justify-between rounded-lg border;
  }

  .selected-option {
    @apply text-grey-5 flex items-center;

    &-empty {
      @apply text-grey-4 flex items-center;
    }
  }

  &[data-state='open'] {
    .icon {
      @apply flex rotate-180;
    }
    .form-field {
      @apply border-secondary border-collapse rounded-lg border;
    }
    .selected-option-empty {
      @apply text-grey-5;
    }
  }
  &[data-state='closed'] {
    .icon {
      @apply flex rotate-0;
    }
  }
}

.select-content {
  @apply z-20 w-[var(--radix-select-trigger-width)] bg-white;
  @apply rounded-lg border-none;
  @apply max-h-[40vh] overflow-auto;
  @apply shadow-drop;
}

.select-item {
  @apply p-4;
  @apply font-body text-base;
  @apply flex cursor-pointer items-center justify-start;
  @apply border-grey-2 border-b last:border-none;

  &[data-disabled] {
    @apply border-grey-3 bg-grey-1 text-grey-4 cursor-not-allowed;
  }

  &[data-highlighted] {
    @apply bg-secondary-lighter-3 rounded focus:outline-none;
  }
}
