.spinner {
  @apply flex items-center justify-center;

  &-primary {
    svg {
      @apply fill-primary;
    }
  }

  &-secondary {
    svg {
      @apply fill-secondary;
    }
  }

  &-tertiary {
    @apply rounded-full bg-white;

    svg {
      @apply fill-grey-4;
    }

    &-large {
      @apply h-[68px] w-[68px];
    }

    &-medium {
      @apply h-[48px] w-[48px];
    }

    &-small {
      @apply h-[32px] w-[32px];
    }
  }

  &-small {
    svg {
      @apply w-[25px];
    }
  }

  &-medium {
    svg {
      @apply w-[40px];
    }
  }

  &-large {
    svg {
      @apply w-[60px];
    }
  }
}
