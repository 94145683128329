.item {
  @apply hover:border-secondary border-grey-2 flex cursor-pointer items-center rounded-lg border bg-white p-4;

  &:not(:last-of-type) {
    @apply mb-2;
  }

  &-checked {
    @apply border-success shadow-drop hover:border-success;

    .item-label {
      @apply font-medium;
    }

    small {
      @apply font-medium;
    }
  }

  &-invalid {
    @apply border-error;
  }

  &-disabled {
    @apply border-grey-2 bg-grey-1 hover:border-grey-2 cursor-not-allowed;

    button,
    label {
      @apply border-grey-3 bg-grey-1 cursor-not-allowed #{!important};
    }
  }

  &-shadow {
    @apply shadow-drop focus-within:border-success border-transparent;
  }

  button {
    &[role='radio'] {
      @apply border-grey-3 inline-flex h-6 w-6 items-center rounded-full border bg-white;

      svg {
        @apply invisible;
      }

      &[data-state='checked'] {
        @apply border-0;
        svg {
          @apply visible;
        }
      }
    }
  }
}
