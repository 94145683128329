.overlay {
  @apply bg-overlay fixed inset-0 z-10;
  animation: fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(2px);
}

.content {
  @apply shadow-drop border-grey-1 fixed z-20 my-4 w-[90vw] max-w-screen-md rounded-xl border bg-white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  @media (max-width: theme('screens.sm')) {
    @apply top-full mb-0 w-full;
    transform: translate(-50%, -100%);
    animation:
      fadeIn 0.4s,
      slideIn 0.4s linear;
  }
}
.content:focus {
  @apply outline-none;
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.6);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes slideIn {
  0% {
    transform: translate(-50%, 10%);
    animation-timing-function: ease-out;
  }
  85% {
    transform: translate(-50%, -104%);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translate(-50%, -100%);
    animation-timing-function: ease-in;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
