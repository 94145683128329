.animate-swing {
  animation: swing 1s linear infinite;
}

.animate-spin {
  animation: spin 700ms linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes swing {
  0% {
    transform: translateX(5%);
  }
  20% {
    transform: translateX(10%);
  }
  60% {
    transform: translateX(12%);
  }
  80% {
    transform: translateX(-10%);
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
