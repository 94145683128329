@import 'global';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Haas Grot Disp R Web';
  src:
    url('../assets/fonts/NeueHaasGrotDispRound-75Bold-Web.woff2') format('woff2'),
    url('../assets/fonts/NeueHaasGrotDispRound-75Bold-Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Haas Grot Disp R Web';
  src:
    url('../assets/fonts/NeueHaasGrotDispRound-55Roman-Web.woff2') format('woff2'),
    url('../assets/fonts/NeueHaasGrotDispRound-55Roman-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Haas Grot Text R Web';
  src:
    url('../assets/fonts/NeueHaasGrotTextRound-75Bold-Web.woff2') format('woff2'),
    url('../assets/fonts/NeueHaasGrotTextRound-75Bold-Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Haas Grot Text R Web';
  src:
    url('../assets/fonts/NeueHaasGrotTextRound-65Medium-Web.woff2') format('woff2'),
    url('../assets/fonts/NeueHaasGrotTextRound-65Medium-Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Haas Grot Text R Web';
  src:
    url('../assets/fonts/NeueHaasGrotTextRound-55Roman-Web.woff2') format('woff2'),
    url('../assets/fonts/NeueHaasGrotTextRound-55Roman-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('../assets/fonts/RobotoMono-65Medium-Web.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lucida Sans';
  src: url('../assets/fonts/LucidaSans-600Bold.woff') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
