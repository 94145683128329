.trigger {
  @apply w-full flex justify-between bg-transparent text-left;

  svg {
    @apply w-3;
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;

    path {
      @apply stroke-secondary;
    }
  }

  &[data-state='open'] {
    svg {
      transform: rotate(180deg);
      transition: all 0.2s ease-in-out;
    }
  }
}

.content {
  @apply text-sm mb-4;

  p {
    @apply text-sm;
  }

  ul {
    @apply list-disc list-inside ml-2 mb-4;
  }
}
