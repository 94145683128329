.checkbox {
  @apply border-grey-3 hover:border-secondary h-6 w-6 rounded border bg-white;

  svg {
    @apply invisible;
  }

  &[data-state='checked'],
  &[data-state='indeterminate'] {
    @apply border-0;

    svg {
      @apply visible;
    }
  }

  &[data-disabled] {
    @apply border-grey-2 bg-grey-1 hover:border-grey-2 cursor-not-allowed;

    + input {
      + label {
        @apply cursor-not-allowed;
      }
    }
  }
}

.label {
  @apply cursor-pointer text-grey-5;

  &[data-disabled] {
    @apply cursor-not-allowed text-grey-4;
  }
}
