.item {
  @apply flex items-center justify-center;
  @apply leading-base text-grey-4 text-xs font-medium uppercase tracking-[0.16em];
  @apply border-grey-3 border-2 border-r-0 bg-white py-[.75rem] px-[20px];

  &:not(:first-of-type):not(:last-of-type) {
    @apply px-2;
  }

  &:first-of-type {
    @apply rounded-l-pill border-l-2;
  }

  &:last-of-type {
    @apply rounded-r-pill border-r-2;
  }

  &:hover {
    @apply text-secondary-lighter-1 border-secondary-lighter-1 bg-secondary-lighter-3;

    + button {
      @apply border-l-secondary-lighter-1;
    }

    span {
      @apply font-medium;
    }
  }

  &-invalid {
    @apply text-error border-error hover:border-error hover:bg-white  #{!important};
  }

  &[data-disabled] {
    @apply border-grey-3 bg-grey-1 text-grey-4 hover:bg-grey-1 cursor-not-allowed;

    + button {
      @apply border-l-grey-3 cursor-not-allowed;
    }
  }

  &[data-state='on'] {
    @apply text-secondary border-secondary bg-secondary-lighter-3 #{!important};

    + button {
      @apply border-l-secondary;
    }

    span {
      @apply font-medium;
    }
  }
}
