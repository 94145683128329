.field {
  @apply font-body leading-base text-grey-5 text-base;
  @apply border-grey-2 rounded-lg border bg-white;
  @apply flex w-full items-center py-4 px-4;
  @apply placeholder:text-grey-4 focus:border-secondary active:border-secondary focus-within:border-secondary;

  input,
  select,
  textarea {
    @apply text-grey-5 m-0 flex-1 border-0 bg-white shadow-none outline-none;

    &:-webkit-autofill {
      box-shadow: 0 0 0 1.25rem white inset;
    }
  }

  &-prefix {
    @apply text-grey-4 mr-2;
  }

  &-valid {
    @apply text-grey-5 #{!important};
  }

  &-disabled,
  &-readonly {
    @apply bg-grey-1 text-grey-4 border-grey-2 hover:border-grey-2 cursor-not-allowed;

    input,
    select,
    textarea {
      @apply cursor-not-allowed bg-transparent;
    }
  }

  &-invalid {
    @apply border-error hover:border-error #{!important};
  }
}

.field:focus-within {
  &-prefix {
    @apply text-grey-5;
  }
}
