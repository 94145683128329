.text-animation {
  @apply font-medium;

  &:after {
    content: '';
    animation: replaceText 5s infinite;
  }

  @keyframes replaceText {
    0%,
    100% {
      content: 'checking your details';
    }
    50% {
      content: 'processing your data';
    }
  }
}
