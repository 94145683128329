.alert {
  @apply flex items-start;
  @apply mb-6 rounded-lg border p-4;

  &-error {
    @apply bg-error-fill border-error;
  }

  &-warning {
    @apply bg-warning-fill border-warning;
  }

  &-info {
    @apply bg-info-fill border-secondary-lighter-1;
  }

  &-success,
  &-celebrate {
    @apply bg-tertiary-lighter-3 border-tertiary;
  }

  &-tip {
    @apply bg-info-fill border-secondary-lighter-1;
  }

  &-light-info {
    @apply bg-info-fill border-secondary-lighter-1;
  }

  &-wait {
    @apply bg-warning-fill border-warning;
  }

  &-closed {
    @apply hidden;
  }
}

.collapsed {
  transform: rotate(-90deg);
  transition: all 0.2s ease-in-out;
}
