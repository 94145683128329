:root {
  // Note: Custom variable values only support SassScript inside `#{}`.
  --font-family-heading: #{inspect($font-family-heading)};
  --font-family-body: #{inspect($font-family-body)};
  --font-family-mono: #{inspect($font-family-mono)};

  --colour-overlay: #{inspect($overlay)};

  @each $size, $value in $font-sizes {
    --font-size-#{$size}: #{$value};
  }

  @each $weight, $value in $font-weights {
    --font-weight-#{$weight}: #{$value};
  }

  @each $height, $value in $line-heights {
    --line-height-#{$height}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --colour-brand-#{$color}: #{$value};
  }

  @each $color, $value in $text-colors {
    --colour-text-#{$color}: #{$value};
  }

  @each $color, $value in $greys {
    --colour-#{$color}: #{$value};
  }

  @each $color, $value in $utility-colors {
    --colour-utility-#{$color}: #{$value};
  }

  @each $color, $value in $gradient-colors {
    --colour-background-#{$color}: #{$value};
  }

  @each $spacing, $value in $spacings {
    --spacing-#{$spacing}: #{$value};
  }

  @each $radius, $value in $border-radius {
    --border-radius-#{$radius}: #{$value};
  }

  @each $shadow, $value in $box-shadows {
    --box-shadow-#{$shadow}: #{$value};
  }

  --swiper-navigation-color: #{$grey-3};
  --swiper-navigation-size: 26px !important;
  --swiper-pagination-color: #{$grey-5};

  .swiper-3d .swiper-slide-shadow {
    background: none;
  }
}
