.checkbox-group {
  @apply flex flex-col;

  &-item {
    @apply hover:border-secondary shadow-drop cursor-pointer rounded-lg border border-transparent bg-white p-4;

    &:not(:last-of-type) {
      @apply mb-2;
    }
  }

  &-checked {
    @apply border-success shadow-drop hover:border-success;

    .label {
      svg path {
        @apply fill-secondary;
      }

      .title {
        @apply font-medium;
      }
    }
  }

  &-invalid {
    @apply border-error;
  }

  &-disabled {
    @apply border-grey-2 bg-grey-1 hover:border-grey-2 cursor-not-allowed shadow-none;

    .checkbox-group-button,
    label,
    span {
      @apply cursor-not-allowed;
    }
  }

  .checkbox-group-button {
    @apply border-grey-3 h-6 w-6 rounded border bg-white;

    svg {
      @apply invisible;
    }
    &[data-state='checked'] {
      @apply border-0;

      svg {
        @apply visible;
      }
    }

    &[data-disabled] {
      @apply border-grey-3 hover:border-grey-3 bg-grey-1 cursor-not-allowed;

      + input {
        + label {
          @apply cursor-not-allowed;
        }
      }
    }
  }
}
