.btn {
  @apply inline-flex items-center justify-center;
  @apply leading-sm text-grey-5 text-center font-mono text-base font-medium uppercase tracking-[0.16em];
  @apply cursor-pointer touch-manipulation select-none py-[.875rem] px-6;
  @apply border-grey-4 rounded-pill border-2 bg-white;
  @apply min-h-[3rem] w-fit transition-all sm:max-w-[20.5rem];

  &[type='submit'] {
    @apply w-full self-center;
  }

  &,
  &:active,
  &:focus {
    @apply outline-none;
  }

  &:not([disabled]):hover {
    @apply bg-secondary-lighter-3 border-secondary;
  }

  &:not([disabled]):active,
  &:not([disabled]):focus-visible {
    @apply bg-secondary-lighter-2 border-secondary;
  }

  &[disabled],
  &-disabled,
  &-loading {
    @apply bg-grey-2 text-grey-4 cursor-not-allowed border-transparent shadow-none #{!important};

    > * {
      @apply pointer-events-none;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      @apply bg-grey-2 text-grey-4 border-transparent shadow-none #{!important};
    }
  }

  &-primary {
    @apply bg-primary min-w-full border-transparent text-white sm:min-w-[20.5rem];

    &:not([disabled]):hover {
      @apply bg-primary-darker-1 border-transparent text-white;
    }

    &:not([disabled]):active,
    &:not([disabled]):focus-visible {
      @apply bg-primary-darker-2 border-transparent text-white;
    }
  }

  &-accent {
    @apply bg-secondary min-w-full border-transparent text-white sm:min-w-[20.5rem];

    &:not([disabled]):hover {
      @apply bg-secondary-darker-1 border-transparent text-white;
    }

    &:not([disabled]):active,
    &:not([disabled]):focus-visible {
      @apply bg-secondary-darker-2 border-transparent text-white;
    }
  }

  &-primary-gradient {
    @apply from-primary to-primary-darker-1 min-w-full border-transparent bg-gradient-to-r text-white sm:min-w-[20.5rem];

    &:not([disabled]):hover {
      @apply to-primary-darker-2 border-transparent bg-gradient-to-r from-[#732514] text-white;
    }

    &:not([disabled]):active,
    &:not([disabled]):focus-visible {
      @apply to-primary-darker-2 border-transparent bg-gradient-to-r from-[#732514] text-white;
    }
  }

  &-outline-primary {
    @apply border-primary text-primary min-w-full bg-white sm:min-w-[20.5rem];

    &:not([disabled]):hover {
      @apply bg-primary-lighter-3 border-primary text-primary;
    }

    &:not([disabled]):active,
    &:not([disabled]):focus-visible {
      @apply bg-primary-lighter-2 border-primary text-primary;
    }
  }

  &-outline-secondary {
    @apply border-secondary text-secondary min-w-full bg-white sm:min-w-[20.5rem];

    &:not([disabled]):hover {
      @apply bg-secondary-lighter-3 border-secondary text-secondary;
    }

    &:not([disabled]):active,
    &:not([disabled]):focus-visible {
      @apply bg-secondary-lighter-2 border-secondary text-secondary;
    }
  }
  &-secondary {
    @apply text-secondary min-w-full border-transparent bg-white shadow-none sm:min-w-[20.5rem];

    &:not([disabled]):hover {
      @apply bg-secondary-lighter-3 text-secondary border-transparent;
    }

    &:not([disabled]):active,
    &:not([disabled]):focus-visible {
      @apply bg-secondary-lighter-2 text-secondary border-transparent;
    }
  }

  &-tertiary {
    @apply text-secondary min-w-full border-transparent bg-transparent shadow-none sm:min-w-[20.5rem];

    &:not([disabled]):hover {
      @apply bg-secondary-lighter-3 text-secondary border-transparent shadow-none;
    }

    &:not([disabled]):active,
    &:not([disabled]):focus-visible {
      @apply bg-secondary-lighter-2 text-secondary border-transparent shadow-none;
    }

    &:disabled,
    &-disabled,
    &-loading {
      @apply text-grey-3 cursor-not-allowed border-transparent bg-transparent shadow-none;

      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        @apply text-grey-3 border-transparent bg-transparent shadow-none;
      }
    }
  }

  &-text {
    @apply text-secondary min-h-fit border-transparent bg-transparent p-0 shadow-none #{!important};

    &:not([disabled]):hover,
    &:not([disabled]):active,
    &:not([disabled]):focus-visible {
      @apply text-secondary-darker-1 border-transparent bg-transparent shadow-none #{!important};
    }

    &:disabled,
    &-disabled {
      @apply text-grey-3 cursor-not-allowed border-transparent bg-transparent shadow-none #{!important};

      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        @apply text-grey-3 border-transparent bg-transparent shadow-none #{!important};
      }
    }
  }

  &-link {
    @apply font-body text-secondary font-normal normal-case tracking-normal;
    @apply cursor-pointer border-transparent bg-transparent p-0 shadow-none #{!important};

    &:not([disabled]):hover,
    &:not([disabled]):active,
    &:not([disabled]):focus-visible {
      @apply border-transparent  bg-transparent text-secondary-darker-1 shadow-none #{!important};
    }

    &:disabled,
    &-disabled {
      @apply text-grey-3 cursor-not-allowed border-transparent bg-transparent shadow-none #{!important};

      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        @apply text-grey-3 border-transparent bg-transparent shadow-none #{!important};
      }
    }
  }

  &-social {
    @apply text-grey-5 flex min-w-full border-transparent bg-white pl-2 shadow-none sm:min-w-[20.5rem] #{!important};

    &:not([disabled]):hover {
      @apply shadow-drop border-transparent #{!important};
    }

    &:not([disabled]):active,
    &:not([disabled]):focus-visible {
      @apply bg-grey-1 border-transparent #{!important};
    }
    &-start {
      @apply mr-4 flex #{!important};
    }
    &-end {
      @apply ml-4 flex #{!important};
    }
  }

  &-shadow {
    @apply shadow-drop;
    &:not([disabled]):active,
    &:not([disabled]):focus-visible &:not([disabled]):hover {
      @apply shadow-drop;
    }
  }
  &-large {
    @apply min-h-[3rem] py-[.75rem] px-6 text-base;
  }

  &-medium {
    @apply min-h-[2.5rem] py-[.75rem] px-6 text-sm;
  }

  &-small {
    @apply min-h-[2rem] py-[.375rem] px-6 text-xs;
  }

  &-wide {
    @apply min-w-full max-w-full;
  }

  &-icon {
    @apply min-h-[3rem] py-[.375rem] px-6 text-base;

    .start {
      @apply mr-[0.8125rem] flex;
    }

    .end {
      @apply ml-6 flex;
    }
  }

  &-icon-only {
    @apply min-h-0 min-w-fit border-transparent bg-transparent p-0 text-transparent shadow-none #{!important};

    .start {
      @apply flex;
    }

    .end {
      @apply flex;
    }
  }

  &-loading,
  &-icon-end {
    @apply justify-between pr-2;
  }
}
