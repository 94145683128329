.react-datepicker__navigation {
  top: 0.5rem;
}

.react-datepicker__day--selected {
  background-color: var(--colour-brand-secondary);
}

.date-with-dot::after {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  background-color: var(--colour-brand-secondary);
  border-radius: 50%;
  margin: 0 auto;
  margin-top: -5px;
}

.date-with-dot.date-with-dot-selected::after {
  background-color: var(--colour-white);
}
