.checkbox {
  @apply border-grey-3 hover:border-secondary h-6 w-6 rounded border bg-white;

  svg {
    @apply invisible;
  }

  &[data-state='checked'] {
    @apply border-0;

    svg {
      @apply visible;
    }
  }
}

.collapsible-content[data-state='open'] {
  animation: slideDown 50ms ease-out;
}
.collapsible-content[data-state='closed'] {
  animation: slideUp 50ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}
