.root {
  @apply rounded-lg drop-shadow-md bg-white;
}

.trigger {
  @apply bg-grey-1 flex w-full items-center rounded-lg p-4 text-left;

  &[data-state='open'] {
    @apply rounded-b-none;
  }

  svg path {
    @apply fill-primary;
  }

  .title {
    @apply text-primary font-medium;
  }
}
