.onfido-sdk-ui-Overlay-face {
  background-position: 50% calc(-30.4375em) !important;
}

.onfido-sdk-ui-Modal-closeButton {
  display: none !important;
}

.onfido-sdk-ui-PageTitle-titleWrapper {
  margin: 0 !important;
  margin-top: var(--spacing-16) !important;
  font-family: var(--font-family-heading) !important;
  font-size: var(--font-size-h1) !important;
  font-weight: 700 !important;
  color: var(--colour-brand-primary) !important;
  min-height: 0 !important;
  text-align: left !important;
}

.onfido-sdk-ui-Welcome-instructions::after {
  content: 'Make sure you are in a well-lit and quiet place.' !important;
  display: block !important;
}

.onfido-sdk-ui-Theme-content {
  padding: var(--spacing-4) !important;
}

.onfido-sdk-ui-Welcome-instructions > ol {
  list-style-type: disc !important;
}
.onfido-sdk-ui-Welcome-instructions > ol li::marker {
  color: var(--osdk-color-content-body) !important;
}

.onfido-sdk-ui-Theme-footer,
.onfido-sdk-ui-NavigationBar-navigation {
  display: none !important;
}

.onfido-sdk-ui-PageTitle-title {
  font-family: var(--font-family-heading) !important;
  font-size: var(--font-size-h1) !important;
  font-weight: 700 !important;
  color: var(--colour-brand-primary) !important;
  min-height: 0 !important;
  margin: 0 !important;
  text-align: left !important;
  margin-left: var(--spacing-2) !important;
}

.onfido-sdk-ui-Theme-content > div {
  justify-content: flex-start !important;
}

.onfido-sdk-ui-Welcome-content,
.onfido-sdk-ui-Welcome-customDescriptions p,
.onfido-sdk-ui-PageTitle-subTitle,
.onfido-sdk-ui-crossDevice-CrossDeviceLink-styledLabel,
.onfido-sdk-ui-QRCode-qrCodeHelpList,
.onfido-sdk-ui-QRCode-qrCodeHelpList li,
.onfido-sdk-ui-Theme-helpList,
.onfido-sdk-ui-crossDevice-CrossDeviceLink-label,
.onfido-sdk-ui-crossDevice-MobileNotificationSent-submessage,
.onfido-sdk-ui-crossDevice-MobileNotificationSent-boldMessage,
.onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadListItem,
.onfido-sdk-ui-crossDevice-Intro-stageMessage,
.onfido-sdk-ui-Uploader-instructionsCopy,
.onfido-sdk-ui-Confirm-message,
.onfido-sdk-ui-crossDevice-CrossDeviceLink-linkText,
.onfido-sdk-ui-Photo-introText,
.onfido-sdk-ui-CameraPermissions-Primer-instructions {
  font-family: var(--font-family-body) !important;
  font-size: var(--font-size-base) !important;
  font-weight: 400 !important;
  color: var(--colour-grey-5) !important;
  text-align: left !important;
  margin-left: 0 !important;
}
.onfido-sdk-ui-Welcome-customDescriptions,
.onfido-sdk-ui-Welcome-content {
  align-self: flex-start !important;
}

.onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadListItem {
  display: flex !important;
  align-items: center !important;
}
.onfido-sdk-ui-crossDevice-CrossDeviceSubmit-icon {
  float: none !important;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-inputContainer {
  width: 100% !important;
  float: none !important;
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}

.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer input {
  font-family: var(--font-family-body) !important;
  font-size: var(--font-size-base) !important;
  font-weight: 400 !important;
  color: var(--colour-grey-5) !important;
  border: 1px solid var(--colour-grey-2) !important;
  border-radius: var(--border-radius-lg) !important;
  background-color: var(--colour-white) !important;
}
.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer input::placeholder {
  color: var(--colour-grey-4) !important;
}
.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer input:focus,
.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer input:focus-visible {
  outline: transparent !important;
  border: 1px solid var(--colour-brand-secondary) !important;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-btn {
  float: none !important;
  padding: 0 1rem !important;
}

.onfido-sdk-ui-IntroVideo-button {
  display: none !important;
}

.ods-button.-action--primary,
.ods-button.-action--secondary {
  border-radius: var(--border-radius-pill) !important;
  border: 2px solid transparent !important;
  background-color: var(--colour-brand-primary) !important;
  color: var(--colour-white) !important;
  font-family: var(--font-family-mono) !important;
  font-size: var(--font-size-base) !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.16em !important;
  cursor: pointer !important;
  text-decoration: none !important;
  box-shadow: none !important;
  transition: all 0.2s ease-in-out !important;
  width: 100% !important;
}
.ods-button.-action--primary:hover {
  background-color: var(--colour-brand-primary-darker-1) !important;
}
.ods-button.-action--primary:active,
.ods-button.-action--primary:focus-visible {
  background-color: var(--colour-brand-primary-darker-2) !important;
}

.ods-button.-action--secondary {
  background-color: var(--colour-white) !important;
  color: var(--colour-brand-secondary) !important;
}
.ods-button.-action--secondary:hover {
  background: var(--colour-secondary-lighter-3) !important;
}
.ods-button.-action--secondary:active,
.ods-button.-action--secondary:focus-visible {
  background: var(--colour-secondary-lighter-2) !important;
}

.onfido-sdk-ui-Theme-link,
.onfido-sdk-ui-Theme-textButton {
  border: transparent !important;
  color: var(--colour-brand-secondary) !important;
  background: transparent !important;
}
.onfido-sdk-ui-Theme-link:hover,
.onfido-sdk-ui-Theme-textButton:hover,
.onfido-sdk-ui-Theme-link:active,
.onfido-sdk-ui-Theme-textButton:active,
.onfido-sdk-ui-Theme-link:focus-visible,
.onfido-sdk-ui-Theme-textButton:focus-visible {
  background: transparent !important;
}

@media (min-width: 480px) {
  .ods-button.-action--primary {
    width: auto !important;
  }
}
