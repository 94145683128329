// Variables
//
// Color system

// scss-docs-start grey-color-variables
$white: #fff !default;
$grey-1: #f5f5f5 !default;
$grey-2: #dbdbdb !default;
$grey-3: #b2b2b2 !default;
$grey-4: #8e8e8e !default;
$grey-5: #434343 !default;
$black: #141414 !default;
// scss-docs-end grey-color-variables

// scss-docs-start grey-colors-map
$greys: (
  'white': $white,
  'grey-1': $grey-1,
  'grey-2': $grey-2,
  'grey-3': $grey-3,
  'grey-4': $grey-4,
  'grey-5': $grey-5,
  'black': $black,
) !default;
// scss-docs-end grey-colors-map

// scss-docs-start overlay-colors-variables
$overlay: rgba(0, 0, 0, 0.2);
// scss-docs-end overlay-colors-variables

// scss-docs-start theme-color-variables
$primary: #ff4b4a !default;
$primary-lighter-3: #ffebeb !default;
$primary-lighter-2: #ffd6d2 !default;
$primary-lighter-1: #ff8d8c !default;
$primary-darker-1: #d33d3b !default;
$primary-darker-2: #4d130d !default;
$secondary: #206eee !default;
$secondary-lighter-3: #f6f9fe !default;
$secondary-lighter-2: #cfe0fc !default;
$secondary-lighter-1: #73a4f5 !default;
$secondary-darker-1: #1c5bc2 !default;
$secondary-darker-2: #102b58 !default;
$tertiary: #45d06c !default;
$tertiary-lighter-3: #effbf2 !default;
$tertiary-lighter-2: #c8f3d4 !default;
$tertiary-lighter-1: #7ee399 !default;
$tertiary-darker-1: #37a355 !default;
$tertiary-darker-2: #14391e !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors--map
$theme-colors: (
  'primary': $primary,
  'primary-lighter-3': $primary-lighter-3,
  'primary-lighter-2': $primary-lighter-2,
  'primary-lighter-1': $primary-lighter-1,
  'primary-darker-1': $primary-darker-1,
  'primary-darker-2': $primary-darker-2,
  'secondary': $secondary,
  'secondary-lighter-3': $secondary-lighter-3,
  'secondary-lighter-2': $secondary-lighter-2,
  'secondary-lighter-1': $secondary-lighter-1,
  'secondary-darker-1': $secondary-darker-1,
  'secondary-darker-2': $secondary-darker-2,
  'tertiary': $tertiary,
  'tertiary-lighter-3': $tertiary-lighter-3,
  'tertiary-lighter-2': $tertiary-lighter-2,
  'tertiary-lighter-1': $tertiary-lighter-1,
  'tertiary-darker-1': $tertiary-darker-1,
  'tertiary-darker-2': $tertiary-darker-2,
) !default;
// scss-docs-end theme-colors-map

// scss-docs-start text-color-variables
$text-body: $grey-5;
$text-body-highlight: $grey-5;
$text-heading: $grey-5;
$text-heading-highlight: $primary;
$text-placeholder: $grey-4;
// scss-docs-end text-color-variables

// scss-docs-start text-colors-map
$text-colors: (
  'body': $text-body,
  'body-highlight': $text-body-highlight,
  'heading': $text-heading,
  'heading-highlight': $text-heading-highlight,
  'placeholder': $text-placeholder,
) !default;
// scss-docs-end text-colos-map

// scss-docs-start utility-color-variables
$success: $tertiary !default;
$success-hover: $tertiary-darker-1 !default;
$success-fill: $tertiary-lighter-3 !default;
$action: $secondary !default;
$action-hover: $secondary-darker-1 !default;
$action-fill: $secondary-lighter-3 !default;
$info: $tertiary-lighter-1 !default;
$info-hover: $secondary !default;
$info-fill: $secondary-lighter-3 !default;
$warning: #ffc83b !default;
$warning-hover: #ffc83b !default;
$warning-fill: #fff9eb !default;
$error: $primary-darker-1 !default;
$error-hover: $primary-darker-1 !default;
$error-fill: #fff5f5 !default;
// scss-docs-end utility-color-variables

// scss-docs-start utility-colors-map
$utility-colors: (
  'success': $success,
  'success-hover': $success-hover,
  'success-fill': $success-fill,
  'action': $action,
  'action-hover': $action-hover,
  'action-fill': $action-fill,
  'info': $info,
  'info-hover': $info-hover,
  'info-fill': $info-fill,
  'warning': $warning,
  'warning-hover': $warning-hover,
  'warning-fill': $warning-fill,
  'error': $error,
  'error-hover': $error-hover,
  'error-fill': $error-fill,
) !default;
// scss-docs-end utility-colors-map

// Gradient
//
// This gradient is added to elements with `.bg-gradient-*`
// scss-docs-start gradient-color-variables
$gradient-primary: linear-gradient(90deg, $primary 0%, $primary-darker-1 100%) !default;
$gradient-primary-dark: linear-gradient(90deg, #732514 0%, $primary-darker-2 100%) !default;
$gradient-primary-light: linear-gradient(270deg, $primary 0%, $primary-lighter-1 100%) !default;
$gradient-primary-lighter: linear-gradient(270deg, $primary-lighter-3 0%, $primary 100%) !default;
$gradient-secondary: linear-gradient(270deg, $secondary-darker-1 0%, $secondary 100%) !default;
$gradient-secondary-dark: linear-gradient(270deg, $secondary-darker-2 0%, $secondary-darker-1 100%) !default;
$gradient-secondary-light: linear-gradient(270deg, $secondary 0%, $secondary-lighter-1 100%) !default;
$gradient-secondary-lighter: linear-gradient(270deg, $secondary-lighter-3 0%, $secondary 100%) !default;
$gradient-tertiary: linear-gradient(90deg$tertiary 0%, $tertiary-darker-1 100%) !default;
$gradient-tertiary-dark: linear-gradient(90deg, $tertiary-darker-1 0%, $tertiary-darker-2 100%) !default;
$gradient-tertiary-light: linear-gradient(90deg, $tertiary-lighter-1 0%, $tertiary 100%) !default;
$gradient-tertiary-lighter: linear-gradient(90deg, $tertiary 0%, $tertiary-lighter-3 100%) !default;
$gradient-grey: linear-gradient(180deg, $white 0%, $grey-1 100%);
$gradient-white: linear-gradient(180deg, $white 0%, rgba(255, 255, 255, 0) 100%);
// scss-docs-end gradient-color-variables

// scss-docs-start gradient-colors-map
$gradient-colors: (
  'gradient-primary': $gradient-primary,
  'gradient-primary-dark': $gradient-primary-dark,
  'gradient-primary-light': $gradient-primary-light,
  'gradient-primary-lighter': $gradient-primary-lighter,
  'gradient-secondary': $gradient-secondary,
  'gradient-secondary-dark': $gradient-secondary-dark,
  'gradient-secondary-light': $gradient-secondary-light,
  'gradient-secondary-lighter': $gradient-secondary-lighter,
  'gradient-tertiary': $gradient-tertiary,
  'gradient-tertiary-dark': $gradient-tertiary-dark,
  'gradient-tertiary-light': $gradient-tertiary-light,
  'gradient-tertiary-lighter': $gradient-tertiary-lighter,
  'gradient-grey': $gradient-grey,
  'gradient-white': $gradient-white,
) !default;
// scss-docs-end gradient-colors-map

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacings map, should you need more variation.

// scss-docs-start spacing-variables-maps
$spacing: 0.25rem !default; // 4px
$spacing-1: $spacing; // 4px
$spacing-2: $spacing * 2; // 8px
$spacing-3: $spacing * 3; // 12px
$spacing-4: $spacing * 4; // 16px
$spacing-6: $spacing * 6; // 24px
$spacing-8: $spacing * 8; // 32px
$spacing-12: $spacing * 12; // 48px
$spacing-16: $spacing * 16; // 64px
$spacing-32: $spacing * 32; // 128px

$spacings: (
  0: 0,
  1: $spacing-1,
  2: $spacing-2,
  3: $spacing-3,
  4: $spacing-4,
  6: $spacing-6,
  8: $spacing-8,
  12: $spacing-12,
  16: $spacing-16,
  32: $spacing-32,
) !default;
// scss-docs-end spacing-variables-maps

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
$font-family-heading: 'Haas Grot Disp R Web', 'Haas Grot Text R Web', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial',
  sans-serif !default;
$font-family-body: 'Haas Grot Text R Web', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif !default;
$font-family-mono: 'Roboto Mono', monospace, sans-serif !default;

// $font-size-base affects the font size of the heading and body text
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$font-size-huge: $font-size-base * 6 !default; //96px

$font-size-h1: $font-size-base * 1.875 !default; //30px
$font-size-h2: $font-size-base * 1.5 !default; //24px
$font-size-h3: $font-size-base * 1.25 !default; //20px
$font-size-h4: $font-size-base !default; //16 px
$font-size-h5: $font-size-base * 0.875 !default; //14px
$font-size-h6: $font-size-base * 0.75 !default; //12px

$font-size-4xl: $font-size-base * 3 !default; //48px
$font-size-3xl: $font-size-base * 2.5 !default; //40px
$font-size-2xl: $font-size-base * 1.875 !default; //30px
$font-size-xl: $font-size-base * 1.5 !default; // 24px
$font-size-lg: $font-size-base * 1.25 !default; //20px
$font-size-sm: $font-size-base * 0.875 !default; //14px
$font-size-xs: $font-size-base * 0.75 !default; //12px

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes-map
$font-sizes: (
  base: $font-size-base,
  huge: $font-size-huge,
  h1: $font-size-h1,
  h2: $font-size-h2,
  h3: $font-size-h3,
  h4: $font-size-h4,
  h5: $font-size-h5,
  h6: $font-size-h6,
  4xl: $font-size-4xl,
  3xl: $font-size-3xl,
  2xl: $font-size-2xl,
  xl: $font-size-xl,
  lg: $font-size-lg,
  sm: $font-size-sm,
  xs: $font-size-xs,
) !default;
// scss-docs-end font-sizes-map

// scss-docs-start font-weights-map
$font-weights: (
  light: $font-weight-light,
  normal: $font-weight-normal,
  medium: $font-weight-medium,
  semibold: $font-weight-semibold,
  bold: $font-weight-bold,
) !default;
// scss-docs-end font-weights-map

// scss-docs-start line-heights-map
$line-heights: (
  base: $line-height-base,
  sm: $line-height-sm,
  lg: $line-height-lg,
) !default;
// scss-docs-end line-heights-map

// scss-docs-start border-variables
$border-width: 1px !default;
$border-style: solid !default;
$border-color: $grey-3 !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius-default: 0.25rem !default;
$border-radius-sm: 0.125rem !default;
$border-radius-md: 0.375rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-xl: 1rem !default;
$border-radius-pill: 3.125rem !default;
// scss-docs-end border-radius-variables

// scss-docs-start border-radius-map
$border-radius: (
  'default': $border-radius-default,
  'sm': $border-radius-sm,
  'md': $border-radius-md,
  'lg': $border-radius-lg,
  'xl': $border-radius-xl,
  'pill': $border-radius-pill,
) !default;
// scss-docs-end border-radius-map

// scss-docs-start box-shadow-variables
$box-shadow-none: 0 0 #0000 !default;
$box-shadow-subtle: 0 0.125rem 0.375rem rgba(178, 178, 178, 0.1) !default;
$box-shadow-soft: -0.375rem 1rem 3rem 0 rgba(178, 178, 178, 0.15) !default;
$box-shadow-drop: 0 0.125rem 0.75rem rgba(178, 178, 178, 0.5) !default;
$box-shadow-heavy: 0 0.5rem 2rem -0.5rem rgba(20, 20, 20, 0.5) !default;
$box-shadow-inset: inset 0 0.063rem 0.125rem rgba(0, 0, 0, 0.1) !default;
$box-shadow-bottom: 0 0.25rem 0.375rem rgba(178, 178, 178, 0.5) !default;
// scss-docs-end box-shadow-variables

// scss-docs-start box-shadow-map
$box-shadows: (
  none: $box-shadow-none,
  inner: $box-shadow-inset,
  subtle: $box-shadow-subtle,
  soft: $box-shadow-soft,
  drop: $box-shadow-drop,
  heavy: $box-shadow-heavy,
  bottom: $box-shadow-bottom,
) !default;
// scss-docs-end box-shadow-map
