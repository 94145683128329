.bpmn-render {
  &-incident {
    @apply bg-error flex h-[20px] w-[20px] -translate-x-1/2 items-center justify-center rounded-[10px] text-sm font-bold text-white;
  }

  &-active {
    @apply bg-success flex h-[20px] w-[20px] -translate-x-1/2 items-center justify-center rounded-[10px] text-sm font-bold text-white;
  }
  &-complete {
    @apply bg-grey-2 flex h-[20px] w-[20px] items-center justify-center rounded-[10px] text-sm font-bold text-white;
  }
}
