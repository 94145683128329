h1,
h2,
h3,
h4,
h5,
h6,
.text-huge,
.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5,
.text-h6 {
  font-family: $font-family-heading;
  line-height: $line-height-sm;
  color: $text-heading;
  margin-bottom: 1rem;
}

h1,
h2,
.text-huge,
.text-h1,
.text-h2 {
  font-weight: $font-weight-bold;
}

h3,
h4,
h5,
h6,
.text-h3,
.text-h4,
.text-h5,
.text-h6 {
  font-weight: $font-weight-medium;
}

.text-huge {
  font-size: $font-size-huge;
}

h1,
.text-h1 {
  font-size: $font-size-h1;
}

h2,
.text-h2 {
  font-size: $font-size-h2;
}

h3,
.text-h3 {
  font-size: $font-size-h3;
}

h4,
.text-h4 {
  font-size: $font-size-h4;
}

h5,
.text-h5 {
  font-size: $font-size-h5;
}

h6,
.text-h6 {
  font-size: $font-size-h6;
}

.text-4xl,
.text-3xl,
.text-2xl,
.text-xl,
.text-lg,
.text-base,
.text-sm,
.text-xs {
  font-family: $font-family-body;
  font-weight: $font-weight-normal;
  color: $text-body;
}

.text-4xl {
  font-size: $font-size-4xl;
}

.text-3xl {
  font-size: $font-size-3xl;
}

.text-2xl {
  font-size: $font-size-2xl;
}

.text-xl {
  font-size: $font-size-xl;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-base {
  font-size: $font-size-base;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-xs {
  font-size: $font-size-xs;
}

b,
i,
strong,
.highlight {
  font-weight: $font-weight-bold;
  color: $text-body-highlight;
}

small {
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

p {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  color: $text-body;

  &:last-child {
    margin-bottom: 0;
  }

  &,
  + p {
    margin-bottom: 1rem;
  }
}

a {
  color: $secondary;
  text-decoration: none;
  transition: color 0.1s linear;

  &:hover,
  &:focus,
  &:active {
    color: $secondary-darker-1;
  }
}
