.link {
  @apply inline-flex items-center justify-center align-bottom whitespace-normal;
  @apply font-body text-secondary cursor-pointer text-left no-underline transition-colors duration-100 ease-linear;

  &:hover,
  &:focus,
  &:active {
    @apply text-secondary-darker-1;
  }

  .start {
    @apply ml-2;
  }

  .end {
    @apply mr-2;
  }

  &-primary {
    @apply text-primary;

    &:hover,
    &:focus,
    &:active {
      @apply text-primary-darker-1;
    }
  }

  &-secondary {
    @apply text-secondary;

    &:hover,
    &:focus,
    &:active {
      @apply text-secondary-darker-1;
    }
  }
}
